import { useCallback, useEffect, useState } from 'react';
import * as QrReader_ from 'react-qr-reader';


import './App.css';

const { QrReader } = QrReader_ as any;


const App = () => {
  const [state, setState] = useState<'ready' | 'connecting' | 'connected' | 'error'>('ready');
  const [data, setData] = useState<string | null>(null);
  const [device, setDevice] = useState<BluetoothDevice | null>(null);
  const [server, setServer] = useState<BluetoothRemoteGATTServer | null>(null);
  const [characteristic, setCharacteristic] = useState<BluetoothRemoteGATTCharacteristic | null>(null);

  const run = useCallback(async (_id: string) => {
    const id = String(_id).padStart(12, '0');
    const UUID = `00001010-0000-0000-0000-${id}`;

    setState('connecting');

    try {
      const _device = await navigator.bluetooth.requestDevice({
        filters: [{
          // services: [0x1010]
          services: [UUID]
        }]
      });

      setDevice(_device);

      if (!_device.gatt) {
        setState('error');
        return;
      }

      const _server = await _device.gatt.connect();
      setServer(_server);

      const service = await _server.getPrimaryService(UUID);
      const _characteristic = await service.getCharacteristic(UUID);
      setCharacteristic(_characteristic);

      if (_characteristic) {
        setState('connected');
      } else {
        setState('error');
        return;
      }
    } catch (error) {
      console.log('2 Something went wrong', error);
      setState('error');
    }
  }, []);

  const send = useCallback((text: string) => {
    if (characteristic) {
      let encoder = new TextEncoder();
      let message = encoder.encode(text);
      characteristic.writeValue(message);
    }
  }, [characteristic]);

  return (
    <div className="App" style={{ maxWidth: 500, margin: 'auto' }}>
      <QrReader
        constraints={{ facingMode: 'environment' }}
        onResult={(result: { text?: string }, error: any) => {
          if (result?.text) {
            setData(result.text)
          }
        }}
      />
      State: {state === 'connecting' ? '🟠' : state === 'connected' ? '🟢' : state === 'error' ? '🔴' : '⚪️'} {state}
      {state !== 'connected' ? (
        <button disabled={!data} style={{ width: '100%', height: 100 }} onClick={() => { if (data) { run(data); } }}>Connect: {data}</button>
      ) : (
        <button style={{ width: '100%', height: 100 }} onClick={() => {
          server?.disconnect();
          setState('ready');
        }}>Disconnect</button>
      )}

      {state === 'connected' && (<div>
        <div style={{ height: 30, width: '100%' }} />
        <button style={{ width: '50%', height: 50 }} onClick={() => send('launch-ktheater')}>KTheater</button>
        <button style={{ width: '50%', height: 50 }} onClick={() => send('launch-mverse')}>Mverse</button>
        <div style={{ height: 30, width: '100%' }} />
        <button style={{ width: '50%', height: 50 }} onClick={() => send('reboot')}>Reboot</button>
        <button style={{ width: '50%', height: 50 }} onClick={() => send('reset-music-volume')}>Reset Volume</button>
        <div style={{ height: 30, width: '100%' }} />
        <button style={{ width: '50%', height: 50 }} onClick={() => send('proximity-sensor-on')}>Proximity Sensor On</button>
        <button style={{ width: '50%', height: 50 }} onClick={() => send('proximity-sensor-off')}>Proximity Sensor Off</button>
        <button style={{ width: '50%', height: 50 }} onClick={() => send('debug-on')}>Debug On</button>
        <button style={{ width: '50%', height: 50 }} onClick={() => send('debug-off')}>Debug Off</button>
      </div>)}
    </div>
  );
}

export default App;
